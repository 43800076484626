import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ClientConfig } from '../interfaces/client-config';

@Injectable({
  providedIn: 'root'
})
export class ClientConfigService {
  constructor(private httpClient: HttpClient) {}

  private _config: ClientConfig;

  get config(): Observable<ClientConfig> {
    return this._config ? of(this._config) : this.load();
  }

  public apiUrl(): Observable<string> {
    return this.config.pipe(map(e => e.qiasphere.serverUrl));
  }

  public casUrl(): Observable<string> {
    return this.config.pipe(map(e => e.qiasphere.casUrl));
  }

  public ssoUrl(): Observable<string> {
    return this.config.pipe(map(e => e.qiasphere.ssoUrl));
  }
  public infoUrl(): Observable<string> {
    return this.config.pipe(map(e => e.qiasphere.infoUrl));
  }
  public supportUrl(): Observable<string> {
    return this.config.pipe(map(e => e.qiasphere.supportUrl));
  }
  public quickStartUrl(): Observable<string> {
    return this.config.pipe(map(e => e.qiasphere.quickStartUrl));
  }
  public userManualUrl(): Observable<string> {
    return this.config.pipe(map(e => e.qiasphere.userManualUrl));
  }

  public emailChangeUrl(): Observable<string> {
    return this.config.pipe(map(e => e.qiasphere.emailChangeUrl));
  }

  public myQiagenUrl(): Observable<string> {
    return this.config.pipe(map(e => e.qiasphere.myQiagenUrl));
  }

  public stateMonitorURL(): Observable<string> {
    return this.config.pipe(map(e => e.qiasphere.stateMonitorURL));
  }

  public insightsURL(): Observable<string> {
    return this.config.pipe(map(e => e.qiasphere.insightsURL));
  }

  public serviceTeamMail(): Observable<string> {
    return this.config.pipe(map(e => e.qiasphere.serviceTeamMail));
  }

  public doiURL(): Observable<string> {
    return this.config.pipe(map(e => e.qiasphere.doiURL));
  }

  public env(): Observable<string> {
    return this.config.pipe(map(e => e.qiasphere.env));
  }

  public instrumentationKey(): Observable<string> {
    return this.config.pipe(map(e => e.ai?.instrumentationKey));
  }

  public hotjar(): Observable<{ enabled: boolean; id: number; sv: number }> {
    return this.config.pipe(map(e => e.hotjar));
  }

  public externalComponents(): Observable<{ [component: string]: any }> {
    return this.config.pipe(map(e => e.externalComponents));
  }

  public ircEnabled(): Observable<boolean> {
    return this.config.pipe(map(e => e.qiasphere.feature.instrumentRemoteConfig));
  }

  public serialNumberGenerationEnabled(): Observable<boolean> {
    return this.config.pipe(map(e => e.qiasphere.feature.serialNumberGeneration));
  }

  public protocolManagementEnabled(): Observable<boolean> {
    return this.config.pipe(map(e => e.qiasphere.feature.protocolManagement));
  }

  load(): Observable<ClientConfig> {
    return this.httpClient.get<ClientConfig>('assets/client-config.json').pipe(
      map((data: ClientConfig) => {
        this._config = data;
        return data;
      })
    );
  }
}
